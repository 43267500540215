import ObjectID from 'bson-objectid';
import {
	AnimalKind,
	AnimalType,
	Averted,
	Farrowing,
	Gender,
	IPregnancyEvent,
	ISowTaskSettings,
	IStemAnimal,
	ITreatment,
	ITreatmentDefinition,
	ITreatmentPlan,
	PregnancyState,
	ScanResult,
	Scanned,
	SowState,
	SowTaskState,
	SowTreatmentTask,
	Treatment,
	TreatmentUnit,
} from 'shared/api/api';
import { AddDaysToDate, isDateSameOrAfter, isDateSameOrBefore } from 'shared/helpers/date-helpers';
import { getDiagnoseNameByTreatDefinitionId } from 'shared/helpers/diagnose-helper/diagnose-helper';
import { calculateDays, isNullOrUndefined } from 'shared/helpers/general-helpers';
import { roundNumber } from 'shared/helpers/number-helper';
import { sortPregnanciesByDate } from 'shared/helpers/pregnancy-helper/sort-pregnancies';
import { calculateAnimalKind1 } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import { getInterval, mapTreatmentsToAnimal } from 'shared/helpers/treatment-helper/treatment-helper';
import { localized } from 'shared/state/i18n/i18n';

export class SowTaskListItem {
	public days?: number;
	public animalNumber?: string;
	public weight?: number;
	public treatment1?: string;
	public treatment2?: string;
	public treatment3?: string;
	public ml1?: number;
	public ml2?: number;
	public ml3?: number;
	public multiplier1?: number;
	public multiplier2?: number;
	public multiplier3?: number;
	public id?: string;
	public isChecked?: boolean;
	public animalKind?: AnimalKind;
	public treatmentUnit?: TreatmentUnit;
	public order?: number | undefined;
}

export class SowTaskTreatMl {
	public treatmentDefinitionId?: string;
	public ml?: number;
}

export const generateSowTaskData = (
	setting: ISowTaskSettings,
	pregnancyEvents: { [key: string]: IPregnancyEvent[] },
	sows: IStemAnimal[],
	treatmentPlans: ITreatmentPlan[],
	treatments: ITreatment[],
	treatmentDefinitions: ITreatmentDefinition[],
) => {
	let data: SowTaskListItem[] = [];
	if (isNullOrUndefined(setting.startIntervalDays) || isNullOrUndefined(setting.endIntervalDays)) {
		return data;
	}
	// Group be id, because of many iterations
	const groupedDefs = treatmentDefinitions.keyValuePairSingle('id');
	const mappedTreatments = mapTreatmentsToAnimal(treatmentPlans, treatments);

	sows.forEach(sow => {
		if (!sow.id) {
			return;
		}
		if (sow.animalNumber === '4565') {
			debugger;
		}

		//Sort descending
		const sorted = sortPregnanciesByDate(pregnancyEvents[sow.id]);
		const state = getStateByPregnancies(sorted);

		// Check main setting requirements
		if (
			(setting.sowState === SowTaskState.MatingPregnant &&
				state === SowState.Pregnant &&
				sorted.find(p => p.state !== PregnancyState.Scanned)?.state === PregnancyState.Mated) ||
			(setting.sowState === SowTaskState.FarrowingNursing &&
				state === SowState.Nursing &&
				sorted.find(p => p.state !== PregnancyState.Scanned)?.state === PregnancyState.Farrowing) ||
			(setting.sowState === SowTaskState.WeaningDry &&
				state === SowState.Dry &&
				sorted.find(p => p.state !== PregnancyState.Scanned)?.state === PregnancyState.Averted)
		) {
			const latest = sorted.filter(p => p.state !== PregnancyState.Scanned)[0];
			if (!latest || !latest.date) {
				return;
			}

			//Calculate days since event
			const days = calculateDays(latest.date!, new Date());
			if (days < setting.startIntervalDays! || days > setting.endIntervalDays!) {
				return;
			}
			const intervalStartDate = AddDaysToDate(latest.date, setting.startIntervalDays!);
			const intervalEndDate = AddDaysToDate(latest.date, setting.endIntervalDays!);

			let listItem = { animalNumber: sow.animalNumber, days, id: sow.id } as SowTaskListItem;
			let hasBeenTreated = 0;
			const animalKind = calculateAnimalKind1(sow, sorted);
			listItem.animalKind = animalKind;

			listItem.weight = roundNumber(animalKind === AnimalKind.Sow ? setting.sowWeight : setting.giltWeight, 1);
			setting.sowTreatmentTasks?.forEach(task => {
				if (task.treatmentDefinitionId) {
					++hasBeenTreated;
				}
				if (
					task.treatmentDefinitionId &&
					sow.id &&
					mappedTreatments[sow.id!] &&
					mappedTreatments[sow.id][task.treatmentDefinitionId]
				) {
					// Sets treatment definitionId on list item, if it hasn't been done within the period
					if (
						mappedTreatments[sow.id][task.treatmentDefinitionId].find(
							(t: ITreatment) =>
								t && (!t.executedDate || isDateSameOrAfter(t.executedDate, intervalStartDate)),
						)
					) {
						--hasBeenTreated;
					} else {
						if (task.treatmentDefinitionId) {
							listItem['treatment' + task.subTaskNumber] = task.treatmentDefinitionId;
							const drugAmount = groupedDefs[task.treatmentDefinitionId]!.subTreatments![0].drugAmount!;
							listItem.treatmentUnit = drugAmount.unitType;
							if (listItem.treatmentUnit === TreatmentUnit.PerKgPig) {
								listItem['multiplier' + task.subTaskNumber] =
									(drugAmount.amount ?? 1) / (drugAmount.perUnit ?? 1);
								listItem['ml' + task.subTaskNumber] =
									Math.round(
										(listItem['multiplier' + task.subTaskNumber] * (listItem.weight ?? 0) +
											Number.EPSILON) *
											10,
									) / 10;
							}
							if (listItem.treatmentUnit === TreatmentUnit.PerAnimal) {
								listItem['ml' + task.subTaskNumber] = drugAmount.amount;
							}
						}
					}
				} else {
					if (task.treatmentDefinitionId) {
						listItem['treatment' + task.subTaskNumber] = task.treatmentDefinitionId;
						const drugAmount = groupedDefs[task.treatmentDefinitionId]!.subTreatments![0].drugAmount!;
						listItem.treatmentUnit = drugAmount.unitType;
						if (listItem.treatmentUnit === TreatmentUnit.PerKgPig) {
							listItem['multiplier' + task.subTaskNumber] =
								(drugAmount.amount ?? 1) / (drugAmount.perUnit ?? 1);
							listItem['ml' + task.subTaskNumber] =
								Math.round(
									(listItem['multiplier' + task.subTaskNumber] * (listItem.weight ?? 0) +
										Number.EPSILON) *
										10,
								) / 10;
						}
						if (listItem.treatmentUnit === TreatmentUnit.PerAnimal) {
							listItem['ml' + task.subTaskNumber] = drugAmount.amount;
						}
					}
				}
			});

			// Don't show animal, if all treatments have been done in the period
			if (hasBeenTreated === 0) {
				return;
			}

			data.push(listItem);
		}
	});

	return data;
};

export const getStateByPregnancies = (pregnancyEvents: IPregnancyEvent[]) => {
	let mated;
	let scanned;
	let farrowed;
	let weaned;
	pregnancyEvents.every(e => {
		if (!mated && e.state === PregnancyState.Mated) {
			mated = e;
			return true;
		}
		if (!scanned && e.state === PregnancyState.Scanned) {
			scanned = e;
			return true;
		}
		if (!farrowed && e.state === PregnancyState.Farrowing) {
			farrowed = e;
			return true;
		}
		if (!weaned && e.state === PregnancyState.Averted) {
			weaned = e;
			return true;
		}
		if (mated && scanned && farrowed && weaned) {
			return false;
		}

		return true;
	});
	const sorted = [mated, scanned, farrowed, weaned];
	sorted.sort((p1, p2) => {
		return p1.date!.valueOf() - p2.date!.valueOf();
	});
	let current: SowState | undefined;
	sorted.forEach(e => {
		if (!e || !e.state) {
			return;
		}
		if (
			e.state === PregnancyState.Mated &&
			current !== SowState.PregnantAndNursing &&
			current !== SowState.Pregnant
		) {
			current =
				current === SowState.Dry || current === undefined ? SowState.Pregnant : SowState.PregnantAndNursing;
		}

		if (
			e.state === PregnancyState.Scanned &&
			(e as Scanned).result === ScanResult.False &&
			current !== SowState.Nursing &&
			current !== SowState.Dry
		) {
			current = current === SowState.Pregnant || current === undefined ? SowState.Dry : SowState.Nursing;
		}

		if (e.state === PregnancyState.Farrowing && (current === SowState.Pregnant || current === undefined)) {
			current = (e as Farrowing).abortion ? SowState.Dry : SowState.Nursing;
		}

		if (
			e.state === PregnancyState.Averted &&
			!(e as Averted).isNursingSow &&
			current !== SowState.Dry &&
			current !== SowState.Pregnant
		) {
			current = current === SowState.Nursing || current === undefined ? SowState.Dry : SowState.Pregnant;
		}
	});
	return current === undefined ? SowState.Dry : (current as SowState);
};

export const generateTreatmentPlans = (
	data: SowTaskListItem[],
	treatmentDefinitions: ITreatmentDefinition[],
	profileId: string,
	siteId: string,
) => {
	const treatmentPlans: ITreatmentPlan[] = [];
	let treatments: ITreatment[] = [];

	// Group be id, because of many iterations
	const groupedDefs = treatmentDefinitions.keyValuePairSingle('id');
	data.forEach(task => {
		if (!task.isChecked) {
			return;
		}

		const defaultPlan = {
			stemAnimalId: task.id,
			gender: Gender.Female,
			isSowActive: true,
			siteId: siteId,
		} as ITreatmentPlan;

		if (task.treatment1) {
			const plan = {
				...defaultPlan,
				treatmentDefinitionId: task.treatment1,
				id: new ObjectID().toHexString(),
			} as ITreatmentPlan;
			treatmentPlans.push(plan);
			treatments = treatments.concat(handleTreatment(plan, groupedDefs[task.treatment1], profileId, task.weight));
		}
		if (task.treatment2) {
			const plan = {
				...defaultPlan,
				treatmentDefinitionId: task.treatment2,
				id: new ObjectID().toHexString(),
			} as ITreatmentPlan;
			treatmentPlans.push(plan);
			treatments = treatments.concat(handleTreatment(plan, groupedDefs[task.treatment2], profileId, task.weight));
		}
		if (task.treatment3) {
			const plan = {
				...defaultPlan,
				treatmentDefinitionId: task.treatment3,
				id: new ObjectID().toHexString(),
			} as ITreatmentPlan;
			treatmentPlans.push(plan);
			treatments = treatments.concat(handleTreatment(plan, groupedDefs[task.treatment3], profileId, task.weight));
		}
	});
	return { treatmentPlans, treatments };
};

export const handleTreatment = (
	treatmentPlan: ITreatmentPlan,
	treatmentDefinition: ITreatmentDefinition,
	profileId: string,
	animalWeight?: number,
) => {
	//Generate treatments:
	let treatments = new Array<ITreatment>();

	for (let i = 0; i < treatmentDefinition.numberOfTreatments!; i++) {
		let genDate = new Date();
		let interval = getInterval(treatmentDefinition.frequency!);
		genDate.setDate(genDate.getDate() + interval * i);

		let treatment = Treatment.fromJS({});
		treatment.id = new ObjectID().toHexString();
		treatment.plannedDate = genDate;
		treatment.treatmentPlanId = treatmentPlan.id;
		treatment.siteId = treatmentPlan.siteId;
		treatment.animalWeight = animalWeight;
		treatment.amount = 0;
		treatment.feedWeight = 0;
		treatment.pigletAmount = 0;
		treatment.treatmentNumber = i + 1;
		treatment.animalType = AnimalType.Sow;

		// Only do the first treatment
		if (treatment.treatmentNumber === 1) {
			treatment.executedDate = treatment.plannedDate;
			treatment.executedByProfileId = profileId;
		}

		treatments.push(treatment);
	}
	return treatments;
};

export const validateSowTaskWorklist = (data: SowTaskListItem[], showAlert: (errorMessage: string) => void) => {
	let isValid = true;

	if (data) {
		isValid = data.every(item => {
			if (item.isChecked) {
				if (!item.weight) {
					showAlert(localized('specifyWeight'));
					return false;
				}
				if (item.treatment1 && !item.ml1) {
					showAlert(localized('SpecifyMLTreatment'));
					return false;
				}
				if (item.treatment2 && !item.ml2) {
					showAlert(localized('SpecifyMLTreatment'));
					return false;
				}
				if (item.treatment3 && !item.ml3) {
					showAlert(localized('SpecifyMLTreatment'));
					return false;
				}
			}
			return true;
		});
	}
	return isValid;
};

export const showTreatmentColumn = (setting: ISowTaskSettings, treatmentNumber: number) => {
	return (
		setting.sowTreatmentTasks &&
		setting.sowTreatmentTasks?.findIndex(
			(e: SowTreatmentTask) => e.subTaskNumber === treatmentNumber && !e.treatmentDefinitionId,
		) > -1
	);
};

export const getTreatmentColumnName = (setting: ISowTaskSettings, treatmentNumber: number) => {
	const treatmentSetting =
		setting.sowTreatmentTasks &&
		setting.sowTreatmentTasks?.find(
			(e: SowTreatmentTask) => e.subTaskNumber === treatmentNumber && e.treatmentDefinitionId,
		);

	return treatmentSetting?.columnText
		? treatmentSetting?.columnText
		: getDiagnoseNameByTreatDefinitionId(treatmentSetting?.treatmentDefinitionId);
};

export const validateSowTaskSetup = (setting: ISowTaskSettings, showAlert: (errorMessage: string) => void) => {
	if (!setting.sowState) {
		showAlert(localized('ChoseState'));
		return false;
	}
	if (isNullOrUndefined(setting.startIntervalDays)) {
		showAlert(localized('VALIDATION_ERROR_NoFrequency'));
		return false;
	}
	if (isNullOrUndefined(setting.endIntervalDays)) {
		showAlert(localized('VALIDATION_ERROR_NoFrequency'));
		return false;
	}
	if (!setting.sowWeight) {
		showAlert(localized('SetWeight'));
		return false;
	}
	if (!setting.giltWeight) {
		showAlert(localized('SetWeight'));
		return false;
	}
	if (!setting.sowState) {
		showAlert(localized('ChoseState'));
		return false;
	}

	return true;
};

export const handleMlCalculation = (row: SowTaskListItem, newWeight: number | undefined, task: number) => {
	return row['multiplier' + task] && newWeight
		? roundNumber(row['multiplier' + task] * newWeight, 1)
		: row['ml' + task];
};

export const handleSowTaskWeightChange = (row: SowTaskListItem, newText: any, columnName: string) => {
	const textstring = newText
		.replace(/(?!^)-/g, '') // removes any - that is not first character in string
		.replace(/,/g, '.') // replaces , with .
		.replace(/\..*/, c => '.' + c.replace(/\./g, () => '')) // ensures there is at most 1 . in string
		.replace(/ /g, ''); // replaces any spaces with nothing

	let rgx = /^-?[0-9]*\.?\d{0,2}$/;

	if (textstring.match(rgx)) {
		return {
			...row,
			[columnName]: textstring,
			ml1: handleMlCalculation(row, textstring, 1),
			ml2: handleMlCalculation(row, textstring, 2),
			ml3: handleMlCalculation(row, textstring, 3),
		} as SowTaskListItem;
	}
};
